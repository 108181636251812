import { getConfig, getFeatureToggle } from 'src/common/eureka';
import { matchPath } from 'react-router-dom';

export function getRandom(digit = 5) {
  return (Math.random() + '').slice(2, digit + 2);
}

export const checkPermissions = (item) => {
  if (item.hasOwnProperty('skipPermission') && item.skipPermission) {
    return true;
  }
  const currentUserPermissions = getConfig('CurrentUserPermissions') || [];
  if (item.permissions) {
    const permissions = Array.isArray(item.permissions)
      ? item.permissions.map((p) => p.toUpperCase())
      : [];
    if (item?.conjunction === 'OR') {
      return permissions.length > 0 && permissions.some((p) => currentUserPermissions.includes(p));
    }

    return permissions.length > 0 && permissions.every((p) => currentUserPermissions.includes(p));
  } else {
    return false;
  }
};

export const checkFeaturetoggle = (item) => {
  if (!item.featureToggle) {
    return true;
  }
  return getFeatureToggle(item.featureToggle);
};

const getMatchedRouterConfig = (routerConfig, path) => {
  for (let item of routerConfig || []) {
    if (matchPath(path, { path: item.path })) return item;
  }
  return null;
};

export const buildSideNavListItems = ({ initListItems, components, getFeatureToggle }) => {
  if (!Array.isArray(components)) {
    return initListItems;
  }

  const listItems = components.reduce(
    (acc, component) => {
      let sidenav = component.config.sidenav;
      if (!(sidenav && Array.isArray(sidenav))) {
        return acc;
      }
      sidenav = sidenav.filter((sideItem) => {
        const matchedItem = getMatchedRouterConfig(component.config.routerConfig, sideItem.router);
        return matchedItem
          ? checkPermissions(matchedItem) && checkFeaturetoggle(matchedItem)
          : true;
      });
      sidenav.forEach((sidenavItem) => {
        if (!sidenavItem.items) return;
        sidenavItem.items = sidenavItem.items.filter((tabItem) => {
          const matchedItem = getMatchedRouterConfig(component.config.routerConfig, tabItem.router);
          return matchedItem
            ? checkPermissions(matchedItem) && checkFeaturetoggle(matchedItem)
            : true;
        });
      });

      return acc.concat(sidenav);
    },
    [...initListItems],
  );

  return listItems;
};

export const THEME_LIST = [
  {
    name: 'sap_horizon',
    displayName: 'SAP Morning Horizon',
    themeId: 'sap_horizon',
    icon: '',
    style: {
      backgroundColor: 'rgb(255, 255, 255)',
      border: '1px solid #758ca4',
      boxShadow: 'inset 0 0 2px #758ca4',
    },
    order: '10',
  },
  {
    name: 'sap_horizon_dark',
    displayName: 'SAP Evening Horizon',
    themeId: 'sap_horizon_dark',
    icon: '',
    style: {
      backgroundColor: 'rgb(29, 45, 63)',
      border: '1px solid #1D2D3E',
      boxShadow: 'inset 0 0 2px #a9b4be',
    },
    order: '20',
  },
  {
    name: 'sap_horizon_hcw',
    displayName: 'SAP High Contrast White',
    themeId: 'sap_horizon_hcw',
    icon: '',
    style: {
      backgroundColor: 'rgb(255, 255, 255)',
      border: '1px solid #000',
    },
    order: '30',
  },
  {
    name: 'sap_horizon_hcb',
    displayName: 'SAP High Contrast Black',
    themeId: 'sap_horizon_hcb',
    icon: '',
    style: {
      backgroundColor: 'rgb(0, 0, 0)',
      border: '1px solid #FFF',
    },
    order: '40',
  },
];

export const LANGUAGES = {
  data: [
    {
      language: 'German',
      languageCode: 'deDE',
      displayLanguage: { nativeLanguage: 'Deutsch', englishLanguage: 'German' },
      displayLanguageCode: 'de-DE',
    },
    {
      language: 'English',
      languageCode: 'enUS',
      displayLanguage: { nativeLanguage: 'English', englishLanguage: 'English' },
      displayLanguageCode: 'en-US',
    },
    {
      language: 'Chinese',
      languageCode: 'zhCN',
      displayLanguage: { nativeLanguage: '中文', englishLanguage: 'Chinese' },
      displayLanguageCode: 'zh-CN',
    },
    {
      language: 'Japanese',
      languageCode: 'jaJP',
      displayLanguage: { nativeLanguage: '日本語', englishLanguage: 'Japanese' },
      displayLanguageCode: 'ja-JP',
    },
    {
      language: 'Czech',
      languageCode: 'csCZ',
      displayLanguage: { nativeLanguage: 'Čeština', englishLanguage: 'Czech' },
      displayLanguageCode: 'cs-CZ',
    },
  ],
};

export const getThemeById = (themeId) => {
  return THEME_LIST.find((ele) => ele.themeId === themeId);
};

export const DEFAULT_THEMEID = THEME_LIST[0].themeId;
