/* eslint-disable */
__webpack_public_path__ = window.__shell_webpack_public_path__ || '/';
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import ReactDOM from 'react-dom';
import { setCustomElementsScopingSuffix } from '@ui5/webcomponents-base/dist/CustomElementsScope';
import { setTheme } from '@ui5/webcomponents-base/dist/config/Theme.js';
import '@ui5/webcomponents/dist/Assets.js';
import '@ui5/webcomponents-fiori/dist/Assets.js';
import '@ui5/webcomponents-react/dist/Assets';
import App from './App';
import axiosWrapper from './axios/axiosWrapper';
import './common/init';
import './styles/index.less';
import { AppearanceManager, eventBus } from './common/eureka';
import { DEFAULT_THEMEID } from './features/common/Utils';

const { setThemeId } = AppearanceManager;

const themeId = new URLSearchParams(window.location.search).get('sap-ui-theme') || DEFAULT_THEMEID;
setThemeId(themeId);
setTheme(themeId);

eventBus.on('appearance-update', (settings) => {
  const { themeId } = settings;
  setThemeId(themeId);
  setTheme(themeId);
});

/* istanbul ignore next */
if (process.env.NODE_ENV !== 'test') {
  setCustomElementsScopingSuffix(process.env.APP_NAME);
}

ReactDOM.render(<App {...axiosWrapper} />, document.getElementById('eureka-app'));
